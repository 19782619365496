import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const ButtonsContainer = styled(Grid)`
    flex-direction: column;
    flex-wrap: initial;
`

export const AnchorImageContainer = styled.a`
    display: block;
    width: 50%;
`

export const Image = styled.img`
    transition: all .3s;
	width: 100%;
	padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;

    &:hover {
        opacity: 0.7;
    }
`