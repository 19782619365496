import styled from 'styled-components'
import { device } from '../../theme/devices'
import { Container, Typography, Grid, Button, CardContent } from '@material-ui/core'

export const SectionContainer = styled.div`
	width: 100%;
	margin-top: ${({ $marginTop }) => $marginTop};
	margin-bottom: ${({ $marginBottom }) => $marginBottom};
	padding-top: ${({ $floatImage }) => $floatImage && '6rem'}; /* 60px */
	padding-bottom: ${({ $floatImage }) => $floatImage && '6rem'}; /* 60px */
	background-color: ${({ odd, theme }) =>
		odd ? theme.palette.grey.section /*theme.palette.inactive.main*/ : 'clear'};

	${device.tablet('max')} {
		padding-top: ${({ $floatImage }) => $floatImage && '4rem'}; /* 40px */
		padding-bottom: ${({ $floatImage }) => $floatImage && '1rem'}; /* 10px */
		margin-bottom: ${({ $floatImage }) => $floatImage && '0'};
	}
`

export const GridContainer = styled(Container)`
	/*height: 80rem;*/
`

export const SectionGrid = styled(Grid)`
	/*height: 100%; */
	padding: 2rem 0 2rem 0;
	align-items: center;
	flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};

	${device.tablet('max')} {
		flex-direction: column-reverse;
	}

	${device.laptopM('min')} {
		padding-right: 7rem;
		padding-left: 7rem;
	}

	${device.tablet('min')} {
		padding-right: ${({ reversed }) => reversed && '7rem'};
		padding-left: ${({ reversed }) => reversed && '7rem'};
	}

	${device.desktop('min')} {
		padding-right: 4.5rem;
		padding-left: 4.5rem;
	}
`

export const SectionItem = styled(Grid)`
	display: flex;
	position: relative;
	padding: ${({ $floatImage, $snapshotContainer }) =>
		$floatImage && $snapshotContainer ? '0 1.6rem' : '1.6rem'};
	justify-content: center;
	width: 50%;

	${device.tablet('min')} {
		align-self: ${({ $floatImage }) => $floatImage && 'flex-start'};
	}

	${device.tablet('max')} {
		display: ${({ $hideImageOnMobile }) => $hideImageOnMobile && 'none'};
		width: initial;
	}
`

export const TextContainer = styled(CardContent)`
	padding-top: ${({ $floatImage }) => $floatImage && '3rem'};
	${device.laptopS('min')} {
		margin-top: ${({ $floatImage }) => !$floatImage && '-8rem'};
	}
`

export const Text = styled(Typography)`
	margin-top: ${({ $marginTop }) => $marginTop};
	margin-bottom: ${({ $marginBottom }) => $marginBottom};
	font-family: ${({ variant }) => {
		if (variant === 'h3') return 'brandon-medium'
		return 'brandon'
	}};
	font-weight: ${({ variant }) => {
		if (['h3', 'h6'].includes(variant)) return 'bold'
	}};
	font-size: ${({ variant }) => {
		if (variant === 'h3') return '6rem'
		else if (variant === 'h6') return '2.2rem'
		return '2rem'
	}};
`

export const Snapshot = styled.img`
	position: ${({ $floatImage }) => $floatImage && 'absolute'};
	align-self: flex-start; /* Fixes the img on Safari (stretches randomly, see: https://stackoverflow.com/questions/57516373/image-stretching-in-flexbox-in-safari) */
	width: 45rem;

	${device.tablet('max')} {
		width: 30rem;
		display: ${({ $floatImage }) => $floatImage && 'none'};
	}
`

export const SectionButton = styled(Button)`
	width: 20rem;
	height: 5.5rem;
	border-radius: 0.8rem;
	margin-top: ${({ $marginTop }) => $marginTop};
	margin-bottom: ${({ $marginBottom }) => $marginBottom};
	font-family: 'brandon-bold';
`
/**
 * display: block; */
/* background-size: cover; */
/* background-repeat: no-repeat; */
/* background-position: center;
 */
