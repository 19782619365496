import styled from 'styled-components'
import { Container, Typography, Link } from '@material-ui/core'

export const FooterContainer = styled(Container)`
    margin-top: 8rem;
    margin-bottom: 4rem;
`

export const Text = styled(Typography)`
    text-align: center;
    color: ${({ theme }) => theme.palette.grey[500]};
    text-transform: uppercase;
	font-size: 1.6rem;
`

export const FooterLink = styled(Link)`
    transition: all .3s;
    &:hover {
        color: ${({ theme }) => theme.palette.secondary.dark};
    }
`