import React from 'react'
import { FooterContainer, Text, FooterLink } from './Footer.elements'
import { Grid } from '@material-ui/core'

export default function Footer() {
	const year = new Date().getFullYear()
	return (
		<FooterContainer maxWidth='lg'>
			<Grid container justify='center' spacing={4}>
				<Grid item>
					<Text variant='body1'>&copy; {year} UNTRAVELLED</Text>
				</Grid>
				<Grid item>
					<Text variant='body1'>
						<FooterLink underline='none' color='inherit' href='https://untravelled.io/terms'>
							TERMS & CONDITIONS
						</FooterLink>
					</Text>
				</Grid>
				<Grid item>
					<Text variant='body1'>
						<FooterLink underline='none' color='inherit' href='https://untravelled.io/privacy'>
							PRIVACY POLICY
						</FooterLink>
					</Text>
				</Grid>
			</Grid>
		</FooterContainer>
	)
}
