import React from 'react'
import { Grid } from '@material-ui/core'
import DownloadOnAppstore from '../../assets/icons/app-store.png'
import DownloadOnPlaystore from '../../assets/icons/google-play.png'
import { AnchorImageContainer, Image, ButtonsContainer } from './StoreButtons.elements'

export default function StoreButtons() {
	return (
		<ButtonsContainer container>
			<Grid item>
				<AnchorImageContainer href='https://apps.apple.com/us/app/id1518832347'>
					<Image src={DownloadOnAppstore} />
				</AnchorImageContainer>
			</Grid>
			{false && <Grid item>
				<AnchorImageContainer href='https://play.google.com/store/apps'>
					<Image src={DownloadOnPlaystore} />
				</AnchorImageContainer>
			</Grid>}
		</ButtonsContainer>
	)
}
