import Title from './title.png'
import TitleMobile from './title_mobile.png'
import Explore from './explore.png'
import ExploreMobile from './explore_mobile.png'
import Community from './community.png'
import CommunityMobile from './community_mobile.png'
import Customize from './customize.png'
import CustomizeMobile from './customize_mobile.png'
import Discover from './discover.png'
import DiscoverMobile from './discover_mobile.png'

export const TitleImg = (isMobile) => {
    return isMobile ? TitleMobile : Title
}

export const ExploreImg = (isMobile) => {
    return isMobile ? ExploreMobile : Explore
}

export const CommunityImg = (isMobile) => {
    return isMobile ? CommunityMobile : Community
}

export const CustomizeImg = (isMobile) => {
    return isMobile ? CustomizeMobile : Customize
}

export const DiscoverImg = (isMobile) => {
    return isMobile ? DiscoverMobile : Discover
}