import React from 'react'
import { ThemeProvider } from './theme'
import { LandingPage } from './pages';

export default function App() {
	return (
		<ThemeProvider>
			<LandingPage />
		</ThemeProvider>
	)
}
