import React from 'react'
import UntravelledIcon from '../../assets/icons/untravelled-logo.png'
import {  NavBar, LogoContainer, Logo, NavTitle, NavButton, NavToolbar } from './AppBar.elements'

export default function AppBar() {
	return (
		<NavBar position='fixed' elevation={0}>
			<NavToolbar className='MuiContainer-maxWidthLg'>
				<LogoContainer edge='start'>
					<Logo src={UntravelledIcon} />
				</LogoContainer>
				<NavTitle variant='h6'>Untravelled</NavTitle>
				<NavButton disableElevation variant='contained' color='secondary' href='#downloads'>
					Download
				</NavButton>
			</NavToolbar>
		</NavBar>
	)
}
