import styled from 'styled-components'
import { AppBar, IconButton, Typography, Button, Toolbar } from '@material-ui/core'

export const NavBar = styled(AppBar)`
    display: flex;
    flex-direction: row;
    justify-content: center;
	background-color: ${({ theme }) => theme.palette.background.paper};
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12);
`

export const NavToolbar = styled(Toolbar)`
    flex-grow: 1;
`

export const LogoContainer = styled(IconButton)`
	pointer-events: none;
	margin-right: ${({ theme }) => theme.spacing(2)}px;
`
export const Logo = styled.img`
    height: 6rem;
    border-radius: 3rem;
`

export const NavTitle = styled(Typography)`
    flex-grow: 1;
    font-size: 2.2rem;
    font-weight: bold;
`

export const NavButton = styled(Button)`
    text-transform: none;
    font-family: 'brandon-medium';
`