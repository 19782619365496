import React from 'react'
import logo from '../../assets/icons/untravelled-logo.png'
import { TitleImg, ExploreImg, DiscoverImg, CustomizeImg, CommunityImg } from '../../assets/images'
import { device } from '../../theme/devices'
import { useMediaQuery } from '@material-ui/core'
import { Section, AppBar, StoreButtons, Footer } from '../../components'

const LandingPage = () => {
	const isMobile = useMediaQuery(device.tablet('max', false))
	//TODO: Sarebbe da mettere forse anche sezione in cui mostriamo la possibilità di condividere Places
	//FIXME: L'ultima sezione ha un'immagine che non c'entra nulla con la descrizione
	//TODO: Fare descrizioni e titoli molto meglio
	return (
		<>
			<AppBar />
			<Section
				floatImage
				marginTop='60px'
				marginBottom='210px'
				odd
				title='Discover your world.'
				imageURL={TitleImg(isMobile)}
				buttonText='TRAVEL NOW'
			/>
			<Section
				reversed
				name='Explore'
				title="Look around, it's yours."
				imageURL={ExploreImg(isMobile)}
				description='Ever wondered how many untravelled places you may have near your city? Open the app and zoom on the map'
			/>
			<Section
				odd
				name='Discover places'
				title='Let Untravelled suggest you interesting places.'
				imageURL={DiscoverImg(isMobile)}
				description='Discover breathtaking places, wether they are near or far away. Available everyday, in your feed'
				buttonText='DISCOVER NOW'
			/>
			<Section
				reversed
				name='Customize'
				title='Many categories to choose from.'
				imageURL={CustomizeImg(isMobile)}
				description='Select your favorite categories during the sign up process or through the settings to receive custom knitted tips just for you'
			/>
			<Section
				odd
				name='Community'
				title='Share your world.'
				imageURL={CommunityImg(isMobile)}
				description='Help other travellers to plan their next trip. Share a place you know, some of your stunning photos, or just a suggestion. You make the difference'
				buttonText='SHARE PLACES'
			/>
			<Section
                id='downloads'
				title='Discover.\nTravel.\nShare.'
				buttons={<StoreButtons />}
                hideImageOnMobile={true}
				imageURL={logo}
			/>
            <Footer />
		</>
	)
}

export default LandingPage
