import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

const _theme = createMuiTheme({
	palette: {
		//Material UI automaticamente usa le sue Palette per elementi e colori: https://material-ui.com/customization/palette/
		primary: {
			main: '#B79B7E'
		},
		secondary: {
			main: '#927C64'
		},
		error: {
			main: '#FF5454'
		},
		info: {
			main: '#007AFF'
		},
		inactive: {
			light: '#F3F3F3',
			main: '#FBF5EE'
		},
		grey: {
			450: '#B2B2B2',
			section: '#f8f8f6',
			550: '#777777'
		}
	},
	typography: {
		htmlFontSize: 10,
		fontFamily: [
			'brandon',
			'brandon-medium',
			'brandon-bold',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Comfortaa',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif'
		].join(',')
	},
	//NOT USED
	fonts: {
		headingPrimary: '8rem',
		headingSecondary: '6rem',
		headingTertiary: '3rem',
		paragraph: '2rem'
	}
})

//const theme = responsiveFontSizes(_theme)

export default _theme
