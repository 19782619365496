const size = {
	extraTiny: '20em',
	mobileS: '25.75em',
	mobileM: '35em',
	mobileL: '38.75em',
	tablet: '46.875em',
	laptopS: '56.25em',
	laptopM: '65.625em',
	laptopL: '75em',
	desktop: '125em',
}

const generateQuery = (type, breakpoint, fullQuery) => {
	const key = type === 'max' ? 'max' : 'min'
	return `${fullQuery ? '@media only screen and ' : ''}(${key}-width: ${breakpoint})`
}

export const device = {
	extraTiny: (type, needsFullQuery = true) => generateQuery(type, size.extraTiny, needsFullQuery),
	mobileS: (type, needsFullQuery = true) => generateQuery(type, size.mobileS, needsFullQuery),
	mobileM: (type, needsFullQuery = true) => generateQuery(type, size.mobileM, needsFullQuery),
	mobileL: (type, needsFullQuery = true) => generateQuery(type, size.mobileL, needsFullQuery),
	tablet: (type, needsFullQuery = true) => generateQuery(type, size.tablet, needsFullQuery),
	laptopS: (type, needsFullQuery = true) => generateQuery(type, size.laptopS, needsFullQuery),
	laptopM: (type, needsFullQuery = true) => generateQuery(type, size.laptopM, needsFullQuery),
	laptopL: (type, needsFullQuery = true) => generateQuery(type, size.laptopL, needsFullQuery),
	desktop: (type, needsFullQuery = true) => generateQuery(type, size.desktop, needsFullQuery)
}
