import React from 'react'
import {
	SectionContainer,
	GridContainer,
	SectionGrid,
	SectionItem,
	Snapshot,
	TextContainer,
	Text,
	SectionButton
} from './Section.elements'
import PropTypes from 'prop-types'

export default function Section(props) {
	const title = props.title
		.split(/(\\n)/)
		.filter((s) => !/(\\n)/.test(s))
		.map((s, i) => (
			<div key={i}>
				<span>{s}</span>
				<br />
			</div>
		))
	const {
		name,
		description,
		buttonText,
		buttons,
		marginBottom,
		marginTop,
		reversed,
		imageURL,
		floatImage,
		hideImageOnMobile,
		odd,
		id
	} = props

	if (buttonText && buttons) throw new Error("buttonText and buttons can't be used together")

	//$prop ==> transient props, only consumed by styled-components
	return (
		<SectionContainer
			$marginTop={marginTop}
			$marginBottom={marginBottom}
			odd={odd}
			$floatImage={floatImage}
			id={id}>
			<GridContainer maxWidth='lg'>
				<SectionGrid container justify='center' reversed={reversed} $floatImage={floatImage}>
					<SectionItem item>
						<TextContainer $floatImage={floatImage}>
							<Text variant='h6' $marginBottom='2rem'>
								{name}
							</Text>
							<Text variant='h3' $marginTop='2rem' $marginBottom='2rem'>
								{title}
							</Text>
							<Text variant='body1' $marginTop='2rem' $marginBottom='2rem'>
								{description}
							</Text>
							{buttonText && (
								<SectionButton
									disableElevation
									variant='contained'
									color='secondary'
									href='#downloads'
									$marginTop='2rem'>
									{buttonText}
								</SectionButton>
							)}
							{buttons && buttons}
						</TextContainer>
					</SectionItem>
					<SectionItem
						$floatImage={floatImage}
						$hideImageOnMobile={hideImageOnMobile}
						$snapshotContainer
						item>
						{imageURL && (
							<Snapshot $floatImage={floatImage} src={imageURL} title={name || 'Section Image'} />
						)}
					</SectionItem>
				</SectionGrid>
			</GridContainer>
		</SectionContainer>
	)
}

Section.defaultProps = {
	hideImageOnMobile: false
}

Section.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	imageURL: PropTypes.string,
	buttonText: PropTypes.string,
	marginTop: PropTypes.string,
	marginBottom: PropTypes.string,
	floatImage: PropTypes.bool,
	hideImageOnMobile: PropTypes.bool,
	odd: PropTypes.bool,
	reversed: PropTypes.bool,
	buttons: PropTypes.element
}
