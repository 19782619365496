import React from 'react'
import { GlobalStyle, Theme } from './'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledComponentsProvider } from 'styled-components'

/**
 * Combines the ThemeProvider from styled-components and the MuiThemeProvider.
 * Also add the StylesProvider injectFirst
 */
const ThemeProvider = ({ children }) => {
	return (
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={Theme}>
				<StyledComponentsProvider theme={Theme}>
					<GlobalStyle />
					{children}
				</StyledComponentsProvider>
			</MuiThemeProvider>
		</StylesProvider>
	)
}

export default ThemeProvider
