import { createGlobalStyle } from 'styled-components'
import { device } from './devices'

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }

    *,
    *::after,
    *::before {
        box-sizing: inherit;
    }

    html {
        /* This define what 1rem is */
        font-size: 62.5%; /*1rem = 10px, 10px/16px (browser default) = 62.5%*/
        scroll-behavior: smooth;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;

        ${device.desktop('min')} {
            font-size: 75%; /*1rem = 12px*/
        }

        ${device.laptopS('max')} {
            font-size: 56.25%; /*1rem = 9px, 9px/16px (browser default) = 56.25%*/
        }

        ${device.extraTiny('max')} {
            font-size: 40%;
        }

        /*${device.laptopL('max')} {
            font-size: 56.25%; /*1rem = 9px, 9px/16px (browser default) = 56.25%/
        }

        ${device.mobileS('max')} {
            font-size: 43%; /*1 rem = 7px/
        }*/
    }

    body {
        font-family: 'brandon',
			'brandon-medium',
			'brandon-bold',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Comfortaa',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif';
        font-weight: 400;
        line-height: 1.6;
    }

    ::selection {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.common.white};
    }

    ::-webkit-scrollbar {
        width: .55rem;
        height: .55rem;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.palette.secondary.main};
    }

    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.palette.inactive.light};
    }
`
export default GlobalStyle
